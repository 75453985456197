/* global System */
// register component(s) with react habitat

export default {
  FeaturedProducts: () =>
    System.import(
      /* webpackChunkName: "FeaturedProducts" */
      './index'
    ),
}
