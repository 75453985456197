/* global System */
// register component(s) with react habitat
import './style.scss'

export default {
  CategoryTeaser: () =>
    System.import(
      /* webpackChunkName: "CategoryTeaser" */
      './index'
    ),
}
