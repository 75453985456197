import '@fancyapps/ui'
import 'jquery-match-height'

export default {
  init() {
    // JavaScript to be fired on all pages

    $('td[style*="border: 2.25pt"]').each(function () {
      let tableContent = $(this).html()
      if ($(this).closest('tbody').children().size() == 1) {
        $(this)
          .closest('.simple-table-wrapper')
          .replaceWith(
            '<div class="key-facts key-facts__default"> ' +
              '<div class="key-facts__content"><p>' +
              tableContent +
              '</p></div></div>'
          )
      }
    })
    $('.simple-table-wrapper thead').each(function () {
      $(this).closest('table').addClass('with-heading')
    })

    $('div[style*="border-left: 4px solid #e85113;"]').each(function () {
      let tableContent = $(this).html()
      $(this).replaceWith(
        '<div class="wp-block-dhsv-article-tip"><div class=" article-tip article-tip--inline">' +
          tableContent +
          '</div></div>'
      )
    })

    if (
      $('body').hasClass('transform_first_list_info_table_of_contents') &&
      $('.table-of-contents').length == 0 &&
      $('.entry-content ol').length
    ) {
      let firstList = $('.entry-content ol:first-of-type'),
        firstListHTML = firstList.html()
      if (firstList.prev().html().toLowerCase().indexOf('inhalt') !== -1) {
        firstList.prev().remove()
      }

      $('.entry-content ol:first-of-type').replaceWith(
        '<div class="table-of-contents">' +
          '<h3 class="table-of-contents__title">INHALT</h3>' +
          '<ol>' +
          firstListHTML +
          '</ol>' +
          '</div>'
      )
    }

    $(document).ready(function () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 500) {
          $('.scrollToTop').fadeIn()
        } else {
          $('.scrollToTop').fadeOut()
        }
      })

      $('.scrollToTop').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 1000)
        return false
      })

      /* Add fancybox form posts images */
      $(
        '.single-post .wrap.container .wp-block-image img, .single-post .wrap.container picture img'
      ).each(function () {
        // check if there is not a link, then show lightbox
        if (!$(this).closest('a').length) {
          let src = $(this).attr('src')
          let caption = $(this).siblings('figcaption').text()
          $(this).wrap(
            '<a href="' +
              src +
              '" data-fancybox data-caption="' +
              caption +
              '"></a>'
          )
        }
      })

      /* Wrap HTML Iframes into container */

      if ($('iframe').length) {
        $('iframe').each(function () {
          if (
            $(this).attr('src').includes('player.vimeo.com') ||
            $(this).attr('src').includes('youtu.be') ||
            $(this).attr('src').includes('www.youtube.com') ||
            $(this).attr('src').includes('www.youtube-nocookie.com')
          ) {
            let isYouTubeBlock = false
            if ($(this).closest('.wp-block-embed').length) {
              isYouTubeBlock = true
            }
            if (!isYouTubeBlock) {
              $(this).wrap('<div class="responsive-embed widescreen"></div>')
            }
          }
        })
      }

      $('.matchHeight').matchHeight()
    })

    $('.mobile-search-toggle').click(function () {
      $('.mobile-header-search-form').toggleClass('active')
    })
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    var $ = require('jquery')
    require('fancybox')($)
    $('.fancybox').fancybox()
  },
}
